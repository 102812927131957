<template>
  <div class="px-8 py-8 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="UN Article Thermal Stability"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of completing the UN article thermal stability test,
              Test 4 (a) is to determine whether an article is sufficiently
              stable when subjected to elevated thermal conditions.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <div class="test-table">
                <table width="100%">
                  <tbody>
                    <tr>
                      <th>Key Parameter</th>
                      <th>Objectives</th>
                      <th>Origin</th>
                      <th>Specs</th>
                    </tr>
                    <tr>
                      <td>
                        Test temperature and time of exposure to that
                        temperature
                      </td>
                      <td>
                        Determine the stability of an article from exposure to
                        elevated temperatures for a defined period
                      </td>
                      <td></td>
                      <td>75°C for 48 hours</td>
                    </tr>
                    <tr>
                      <td>Test oven</td>
                      <td>
                        Ensure adequate temperature control and protection
                        against thermal runaway
                      </td>
                      <td></td>
                      <td>
                        Ventilated, explosion-proof electrical, thermostatic
                        control to maintain and record the temperature at 75 ±
                        2°C, protection against thermal run-away if the
                        thermostat malfunctions (independent thermal cutoff)
                      </td>
                    </tr>
                    <tr>
                      <td>Self-heating temperature change</td>
                      <td>
                        Parameter for characterizing the thermal stability of an
                        article
                      </td>
                      <td></td>
                      <td>3°C or greater</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Ignition or explosion</td>
                    <td>Visual post-test inspection</td>
                    <td>Damage to oven, flame traces, etc. Forbidden</td>
                  </tr>
                  <tr>
                    <td>Article temperature</td>
                    <td>Thermocouple log</td>
                    <td>
                      Difference between oven and article of 3°C or greater.
                      Forbidden
                    </td>
                  </tr>
                  <tr>
                    <td>Article casing or outer package damage</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Damage to unpackaged article or outer package. Forbidden
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dangerous [subjective] exudation of explosive substance
                    </td>
                    <td>Visual post-test inspection</td>
                    <td>Explosive visible outside the article. Forbidden</td>
                  </tr>
                </tbody>
              </table>
              <p class="p-light">*OR relationship</p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-3">
            <TitleComponent
              class="py-8"
              title="No Reaction (-) Example Plot"
              size="medium"
            />
            <a
              target="_blank"
              :href="require('../../assets/IPSeries/nogoplot-min.png')"
            >
              <img
                class="m-auto"
                style="width:80%;"
                src="@/assets/IPSeries/nogoplot-min.png"
              />
            </a>
          </div>
        </div>
      </div>
      <UnSideBar class="w-full sm:w-1/5"></UnSideBar>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import UnSideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    UnSideBar
  }
};
</script>

<style lang="scss"></style>
